import { Button } from "@/Components/ui/button";
import { Input } from "@/Components/ui/input";
import { ScrollArea } from "@/Components/ui/scroll-area";
import {
    Sheet,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle
} from "@/Components/ui/sheet";
import { ucwords } from "@/Hooks/Functions";
import { cn } from "@/lib/utils";
import Empty from "@/Modules/Empty";
import { LaravelPagination } from "@/Modules/LaravelPagination";
import { usePromptBuilder } from "@/Pages/HomeV2/store";
import { Icon } from "@iconify/react";
import { useGlobalModals } from "@state/store";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "ahooks";
import { useCallback, useMemo, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";


const fetchArtStyles = async (pageParam = 1, search = "") => {
    const { data } = await window.axios.get(route("artstyles.paginate", { active: 1, per_page: 30, page: pageParam, search: search }))
    return data;
}
export default function ArtStylesModal() {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, { wait: 500 });

    const { data, isLoading } = useQuery({
        queryKey: ['active-art-styles', page, debouncedSearch],
        queryFn: () => fetchArtStyles(page, debouncedSearch)
    })
    const setPromptBuilder = usePromptBuilder(state => state.set);
    const artStyleModal = useGlobalModals(state => state.artStyleModal);
    const setArtStyleModal = useGlobalModals(state => state.setArtStyleModal)

    const list = useMemo(() => data?.data || [], [data]);
    const links = useMemo(() => data?.links || [], [data]);

    const onSelectStyle = useCallback((style) => {
        setPromptBuilder('artStyle', style.name);
        setArtStyleModal(false);
    }, [setArtStyleModal, setPromptBuilder]);

    return <Sheet open={artStyleModal} onOpenChange={(v) => setArtStyleModal(v)}>
        <SheetContent className="z-[72] isolate">
            <SheetHeader>
                <SheetTitle className="font-nunito italic">Art Styles</SheetTitle>
            </SheetHeader>
            <div className="w-full py-3 relative">
                <Icon icon="mdi:search" className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground" />
                <Input className="px-8 rounded-xl" value={search} placeholder="Search art styles..." onChange={(e) => setSearch(e.target.value)} />
                {search.length > 0 && <Icon onClick={() => setSearch("")} icon="mdi:close" className="cursor-pointer hover:text-cyan-600 absolute right-3 top-1/2 -translate-y-1/2 text-muted-foreground" />}
            </div>
            <ScrollArea className="h-[calc(100vh-170px)] relative flex space-y-2 flex-1 pl-0 pr-3">
                <ResponsiveMasonry columnsCountBreakPoints={{ 400: 2 }}>
                    <Masonry gutter="1em">
                        {list.map(style => <div key={style.id}
                            className={cn("rounded-2xl group w-full bg-transparency aspect-auto relative aspect-auto   shadow-xl border")}>
                            <img src={style.image_url} className="h-fit min-h-[100px] rounded-t-2xl z-10 mx-auto" />
                            <div className="bg-white text-xs px-2 truncate whitespace-nowrap rounded-b-2xl text-primary py-2">{ucwords(style.name)}</div>
                            <div className="w-full h-full rounded-2xl bg-primary/50 absolute-center group-hover:visible invisible"></div>
                            <Button tip="Choose Style" onClick={() => onSelectStyle(style)} className="absolute-center z-20 group-hover:visible invisible" variant="theme">Choose</Button>
                        </div>)}
                    </Masonry>
                </ResponsiveMasonry>
                {list.length === 0 && !isLoading && <Empty className="h-full w-full absolute-center" icon="fluent-mdl2:text-box" text="No art styles found" />}
            </ScrollArea>
            <SheetFooter className="px-2 pb-4 flex flex-col items-center justify-center pt-2 w-full">
                {list.length > 0 && <LaravelPagination dense links={links} navigate={setPage} returnUrl={false} />}
            </SheetFooter>
        </SheetContent>
    </Sheet>
}
